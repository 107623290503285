"use server";
import { simulateCart } from "../apis/client_apis";
import {
  getProcurementProfileData,
  procurementSimulate,
} from "../apis/server_apis";
import { SquidAuthResponse } from "../types/auth";
import {
  ProcurementSimulateProps,
  ProcurementSimulateResponse,
} from "../types/order";

export type ProcurementCheckoutRouteData = {
  simulate: ProcurementSimulateResponse;
  businessDetails: SquidAuthResponse;
};

export type ProcurementCheckoutRouteProps = {
  simulateProp: ProcurementSimulateProps;
};

export const getProcurementCheckoutRouteData = async (
  props: ProcurementCheckoutRouteProps
): Promise<ProcurementCheckoutRouteData> => {
  const resolved = await Promise.all([
    await getProcurementProfileData(),
    await procurementSimulate(props.simulateProp),
  ]);
  const businessDetails = resolved[0];
  const simulate = resolved[1];
  return { simulate, businessDetails };
};
